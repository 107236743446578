import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, firestoreIncrement, firebaseArrayRemove, firebaseArrayUnion } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import localizeFilter from '@/filters/localize.filter'
import useNotifications from '@/composables/useNotifications'

export default {
  state: {
    tagsCollections: {},
    tagsCollectionsReset: 0,
    tagsCollectionsFilters: {
      searchStr: ''
    },
    tagsCollectionsShow: {
      add: false,
      edit: null,
      delete: null,
      addTag: null
    },
    tagsCollectionsSort: {
      field: 'Collection',
      direction: 'asc'
    }
  },
  mutations: {
    resetTagsCollections(state) {
      state.tagsCollectionsReset = Date.now()
    },
    deleteTagFromAllCollections(state, tagId) {
      if (tagId) {
        let tagsCollectionsIds = Object.keys(state.tagsCollections)

        if (tagsCollectionsIds.length) {
          tagsCollectionsIds = tagsCollectionsIds.filter(tagsCollectionId => {
            return (state.tagsCollections[tagsCollectionId].tags && state.tagsCollections[tagsCollectionId].tags.includes(tagId))
          })
        }

        if (tagsCollectionsIds.length) {
          for (const tagsCollectionId of tagsCollectionsIds) {
            const newTags = state.tagsCollections[tagsCollectionId].tags.slice(0)
            newTags.splice(newTags.indexOf(tagId), 1)
            state.tagsCollections[tagsCollectionId].tags = newTags
          }
        }
      }
    },
    addTagsCollection(state, { tagsCollectionId, data }) {
      if (tagsCollectionId && data) {
        state.tagsCollections[tagsCollectionId] = data
      }
    },
    updateTagsCollection(state, { tagsCollectionId, data }) {
      if (tagsCollectionId && data) {
        if (!state.tagsCollections[tagsCollectionId]) {
          state.tagsCollections[tagsCollectionId] = {}
        }

        for (const key of Object.keys(data)) {
          state.tagsCollections[tagsCollectionId][key] = data[key]
        }
      }
    },
    deleteTagsCollectionFromStore(state, tagsCollectionId) {
      if (tagsCollectionId && state.tagsCollections[tagsCollectionId]) {
        delete state.tagsCollections[tagsCollectionId]
      }
    },
    setTagsCollectionsFiltersSearchStr(state, value) {
      if (!value) { value = '' }
      state.tagsCollectionsFilters.searchStr = value
    },
    setTagsCollectionsShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.tagsCollectionsShow[field] = show[field]
        }
      }
    },
    setTagsCollectionsSortDirection(state, direction) {
      state.tagsCollectionsSort.direction = direction
    },
    setTagsCollectionsSortField(state, field) {
      state.tagsCollectionsSort.field = field
    },
    clearTagsCollections(state) {
      state.tagsCollections = {}
      state.tagsCollectionsReset = 0
    },
    clearTagsCollectionsFilters(state) {
      state.tagsCollectionsFilters = {
        searchStr: ''
      }
    },
    clearTagsCollectionsShow(state) {
      state.tagsCollectionsShow = {
        add: false,
        edit: null,
        delete: null,
        addTag: null
      }
    },
    clearInfo(state) {
      state.tagsCollections = {}
      state.tagsCollectionsFilters = {
        searchStr: ''
      }
      state.tagsCollectionsShow = {
        add: false,
        edit: null,
        delete: null,
        addTag: null
      }
      state.tagsCollectionsSort = {
        field: 'Collection',
        direction: 'asc'
      }
    }
  },
  actions: {
    async fetchTagsCollections({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }
      if (!getters.getLimitNumber('maxTagsCollections')) { return }

      const request = {
        wheres: [['owner', '==', getUid()]],
        order: [['timestamp', 'asc']]
      }

      const tarifLimit = getters.countFetchLimit('maxTagsCollections')
      if (tarifLimit === undefined || tarifLimit === 0) {
        return false
      } else if (tarifLimit && Number.isInteger(tarifLimit)) {
        request.maxLimit = tarifLimit
      }

      const t = tracePerformance('fetchTagsCollections')
      t.start()

      try {
        const tagsCollections = await getFirestoreDocs({
          collectionName: 'tagsCollections',
          ...request
        })

        if (tagsCollections) {
          for (const collection of tagsCollections) {
            await dispatch('tagsCollectionAdded', { tagsCollectionId: collection.id, data: collection.data, increaseTotalNumber: 0 })
          }
        }

        await commit('setLoadedAll', { field: 'tagsCollections', value: true })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTagsCollections', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchTagsCollection({ commit, dispatch, getters }, tagsCollectionId) {
      if (getters.lessonStep) { return }

      if (!tagsCollectionId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('fetchTagsCollection')
      t.start()

      try {
        const data = await getFirestoreDoc('tagsCollections', tagsCollectionId)

        if (data) {
          await dispatch('tagsCollectionAdded', { tagsCollectionId, data, increaseTotalNumber: 0 })
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTagsCollection', params: { tagsCollectionId } })
      } finally {
        t.stop()
      }
    },
    async createTagsCollection({ commit, dispatch, getters }, data) {
      if (getters.lessonStep || !getters.online) { return }

      if (!getters.getAvailableLimitNumber('tagsCollectionsReset', 'tagsCollections', 'maxTagsCollections') || !getters.getLimitNumber('maxTagsCollections') || (getters.userStats('tagsCollections') > getters.getLimitNumber('maxTagsCollections'))) {
        commit('setAppShowField', { payWall: true })
        return
      }

      if (!data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const tagsCollectionId = createId('tagsCollections')

      if (!data.tags) { data.tags = [] }

      const syncTimestamp = new Date()

      data.timestamp = syncTimestamp
      data.owner = getUid()

      const t = tracePerformance('createTagsCollection')
      t.start()

      const { toastify } = useNotifications()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'tagsCollections',
            id: tagsCollectionId,
            data,
            updateStats: 1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagsCollectionAdded', { tagsCollectionId, data, increaseTotalNumber: 1 })

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createTagsCollection', params: { data }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async setTagsCollectionsShow({ commit, getters }, { tagsCollectionId, from, button }) {
      if (!button) { return }

      await commit('clearTagsCollectionsShow')
      commit('setDataBarIsOpen', false)

      if ((button === 'add' || button === 'edit' || button === 'addTag') && !getters.getAvailableLimitNumber('tagsCollectionsReset', 'tagsCollections', 'maxTagsCollections')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      if (button === 'add') {
        commit('setTagsCollectionsShowField', { add: true })
      } else if (button === 'edit') {
        commit('setTagsCollectionsShowField', { edit: tagsCollectionId })
      } else if (button === 'delete') {
        commit('setTagsCollectionsShowField', { delete: tagsCollectionId })
      } else if (button === 'addTag') {
        commit('setTagsCollectionsShowField', { addTag: tagsCollectionId })
      }

      if (from) { logAnalyticsEvent('setTagsCollectionsShow', { button, from }) }
    },
    async tagsCollectionAdded({ commit, dispatch, getters }, { tagsCollectionId, data, increaseTotalNumber = 0 }) {
      if (!tagsCollectionId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('addTagsCollection', { tagsCollectionId, data })

      if (data.tags && Array.isArray(data.tags) && data.tags.length) {
        for (const tagId of data.tags) {
          if (!getters.tags[tagId]) {
            await dispatch('fetchTag', tagId)
          }
        }
      }

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'tagsCollections', number: increaseTotalNumber })
      }

      await commit('resetTagsCollections')
    },
    async editTagsCollectionInfo({ commit, dispatch, getters }, { tagsCollectionId, data }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!tagsCollectionId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxTagsCollections') || (getters.userStats('tagsCollections') > getters.getLimitNumber('maxTagsCollections'))) {
        commit('setAppShowField', { payWall: true })
        return
      }

      const t = tracePerformance('editTagsCollectionInfo')
      t.start()

      const syncTimestamp = new Date()

      const { toastify } = useNotifications()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'tagsCollections',
            id: tagsCollectionId,
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagsCollectionEdited', { tagsCollectionId, data })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'editTagsCollectionInfo', params: { tagsCollectionId, data }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async addTagToTagsCollection({ commit, dispatch, getters }, { tagsCollectionId, tagId }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!tagsCollectionId || !tagId || !getters.tagsCollections[tagsCollectionId] || !getters.tagsCollections[tagsCollectionId].tags || getters.tagsCollections[tagsCollectionId].tags.includes(tagId)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxTagsCollections') || (getters.userStats('tagsCollections') > getters.getLimitNumber('maxTagsCollections'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const t = tracePerformance('addTagToTagsCollection')
      t.start()

      const syncTimestamp = new Date()

      const { toastify } = useNotifications()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('AddingTagToCollection') + '...', { timeout: null })

        let tags = []
        if (getters.tagsCollections[tagsCollectionId] && getters.tagsCollections[tagsCollectionId].tags) {
          tags = getters.tagsCollections[tagsCollectionId].tags.slice(0)
        }

        if (!tags.includes(tagId)) {
          tags.push(tagId)
        }

        const data = { tags }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'tagsCollections',
            id: tagsCollectionId,
            data: {
              tags: firebaseArrayUnion(tagId)
            },
            logDataInfo: data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagsCollectionEdited', { tagsCollectionId, data })
        toastify.replace(toastId, localizeFilter('TagAddedToCollection'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'addTagToTagsCollection', params: { tagsCollectionId, tagId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteTagFromTagsCollection({ commit, dispatch, getters }, { tagsCollectionId, tagId }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!tagsCollectionId || !tagId || !getters.tagsCollections || !getters.tagsCollections[tagsCollectionId] || !getters.tagsCollections[tagsCollectionId].tags || !getters.tagsCollections[tagsCollectionId].tags.includes(tagId)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxTagsCollections') || (getters.userStats('tagsCollections') > getters.getLimitNumber('maxTagsCollections'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const t = tracePerformance('deleteTagFromTagsCollection')
      t.start()

      const syncTimestamp = new Date()

      const { toastify } = useNotifications()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('DeletingTagFromCollection') + '...', { timeout: null })

        let tags = []
        if (getters.tagsCollections[tagsCollectionId] && getters.tagsCollections[tagsCollectionId].tags) {
          tags = getters.tagsCollections[tagsCollectionId].tags.slice(0)
        }

        if (tags.includes(tagId)) {
          tags.splice(tags.indexOf(tagId), 1)
        }

        const data = { tags }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'tagsCollections',
            id: tagsCollectionId,
            data: {
              tags: firebaseArrayRemove(tagId)
            },
            logDataInfo: data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagsCollectionEdited', { tagsCollectionId, data })
        toastify.replace(toastId, localizeFilter('TagDeletedFromCollection'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteTagFromTagsCollection', params: { tagsCollectionId, tagId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async tagsCollectionEdited({ commit }, { tagsCollectionId, data }) {
      if (!tagsCollectionId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('updateTagsCollection', { tagsCollectionId, data })
      await commit('resetTagsCollections')
    },
    async deleteTagsCollection({ commit, dispatch, getters }, { tagsCollectionId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!tagsCollectionId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteTagsCollection')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('DeletingCollection') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'tagsCollections',
            id: tagsCollectionId,
            updateStats: -1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tagsCollectionDeleted', { tagsCollectionId, increaseTotalNumber: -1 })
        toastify.replace(toastId, localizeFilter('CollectionDeleted'), 'success')
        logAnalyticsEvent('tagsCollectionDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteTagsCollection', params: { tagsCollectionId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteAllTagsCollections({ commit, dispatch, getters }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.userStats('tagsCollections')) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteAllTagsCollections')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const tagsCollectionsToDelete = []

        const request = {
          wheres: [['owner', '==', getUid()]]
        }

        const tagsCollections = await getFirestoreDocs({
          collectionName: 'tagsCollections',
          ...request
        })

        if (tagsCollections) {
          for (const tagsCollection of tagsCollections) {
            if (!tagsCollectionsToDelete.includes(tagsCollection.id)) { tagsCollectionsToDelete.push(tagsCollection.id) }
          }
        }

        if (!tagsCollectionsToDelete.length) {
          toastify.replace(toastId, localizeFilter('Deleted'), 'success')
          return true
        }

        const batchData = []

        for (const tagsCollectionId of tagsCollectionsToDelete) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'tagsCollections',
            id: tagsCollectionId
          })
        }

        if (tagsCollectionsToDelete.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              tagsCollections: firestoreIncrement(tagsCollectionsToDelete.length * -1)
            },
            noLogs: true
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        for (const tagsCollectionId of tagsCollectionsToDelete) {
          await dispatch('tagsCollectionDeleted', { tagsCollectionId, increaseTotalNumber: -1 })
        }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('allTagsCollectionsDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAllTagsCollections', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async tagsCollectionDeleted({ commit }, { tagsCollectionId, increaseTotalNumber = 0 }) {
      if (!tagsCollectionId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('deleteTagsCollectionFromStore', tagsCollectionId)

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'tagsCollections', number: increaseTotalNumber })
      }

      await commit('resetTagsCollections')
    },
    clearTagsCollectionsFiltersClicked({ commit }, from) {
      commit('clearTagsCollectionsFilters')
      if (from) { logAnalyticsEvent('clearTagsCollectionsFiltersClicked', { from }) }
    }
  },
  getters: {
    tagsCollections: s => s.tagsCollections,
    tagsCollectionsReset: s => s.tagsCollectionsReset,
    filteredTagsCollections: (s, getters) => {
      if (getters.tagsCollectionsReset) {
        //
      }

      let tagsCollectionIds = Object.keys(s.tagsCollections)

      if (getters.tagsCollectionsFilters.searchStr && getters.tagsCollectionsFilters.searchStr.length) {
        const anyTagContainsSearchStr = (tagsArr) => {
          if (!tagsArr || !tagsArr.length) { return false }
          for (const tagId of tagsArr) {
            if (
              getters.tags[tagId]
              && ((getters.tags[tagId].name && getters.tags[tagId].name.toLowerCase().includes(s.tagsCollectionsFilters.searchStr.toLowerCase()))
                || (getters.tags[tagId].comment && getters.tags[tagId].comment.toLowerCase().includes(s.tagsCollectionsFilters.searchStr.toLowerCase()))
                || (getters.tags[tagId].icon && getters.tags[tagId].icon.toLowerCase().includes(s.tagsCollectionsFilters.searchStr.toLowerCase()))
              )
            ) { return true }
          }
          return false
        }

        tagsCollectionIds = tagsCollectionIds.filter(tagsCollectionId => {
          return (
            (s.tagsCollections[tagsCollectionId]
              && (
                (
                  s.tagsCollections[tagsCollectionId].name
                  && s.tagsCollections[tagsCollectionId].name.toLowerCase().includes(getters.tagsCollectionsFilters.searchStr.toLowerCase())
                )
                || (
                  s.tagsCollections[tagsCollectionId].tags
                  && s.tagsCollections[tagsCollectionId].tags.length
                  && anyTagContainsSearchStr(s.tagsCollections[tagsCollectionId].tags)
                )
              )
            )
          )
        })
      }

      const limit = getters.getLimitNumber('maxTagsCollections')
      if (tagsCollectionIds.length > limit) { tagsCollectionIds.length = limit }

      if (tagsCollectionIds.length > 1) {
        const sortParameter = s.tagsCollectionsSort.field
        const sortType = s.tagsCollectionsSort.direction

        if (sortParameter === 'Collection' || !sortParameter) {
          tagsCollectionIds.sort((a, b) => {
            const nameA = s.tagsCollections[a] ? s.tagsCollections[a].name.toLowerCase() : ''
            const nameB = s.tagsCollections[b] ? s.tagsCollections[b].name.toLowerCase() : ''

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        }

        if (sortType === 'desc') { tagsCollectionIds.reverse() }
      }

      return tagsCollectionIds
    },
    tagsCollectionsFilters: s => s.tagsCollectionsFilters,
    tagsCollectionsFiltered: s => {
      if (s.tagsCollectionsFilters.searchStr) { return true }
      return false
    },
    tagsCollectionsShow: s => s.tagsCollectionsShow,
    tagsCollectionsSort: s => s.tagsCollectionsSort
  }
}